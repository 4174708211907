import React, { useState, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import BrowserInfo from './BrowserInfo'


export default function TroubleShootTool(props) {
  const [ sessionStart, setSessionStart ] = useState(false);

  const startSession = () => {
    setSessionStart(true);
  }

  return (
    <Fragment>
      <Box align="center" my={4}>
        <Typography variant="h4" align="center" component="h1" gutterBottom>
          Client Troubleshooting Tool
        </Typography>
        <Typography variant="subtitle1" align="center" component="h4" gutterBottom>
          This tool will collect data about your browser and network including, what browser you are using, your IP address, cookies, and DNS information. If you want to share this with your technical support representative and agree to this press start.
        </Typography>
        {
          !sessionStart && (
            <Button onClick={startSession} variant="contained" color="primary">
              Start Session
            </Button>
          )
        }
      </Box>
      { sessionStart && (
        <Fragment>
          <Divider />
          <BrowserInfo />
        </Fragment>
      )}
    </Fragment>
  );
}