import React, { useState, useEffect, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ApiUrl from '../../services/ApiUrl'

export default function BrowserInfo() {
  const [ hasCookies ] = useState(!!window.navigator.cookieEnabled);
  const [ userAgent, setUserAgent ] = useState();
  const [ time, setTime ] = useState();
  const [ sesstionStarted, setSesstionStarted ] = useState(false);
  const [ popID, setPopID ] = useState();
  const [ HTTPVersion, setHTTPVersion ] = useState();
  const [ clientIP, setClientIP ] = useState();
  const [ sessionID, setSessionID ] = useState();

  useEffect(() => {
    fetch(
      `/egde-check.json`,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json"
        })
      }
    )
      .then(response => {
        setPopID(response.headers.get('x-amz-cf-pop') || 'no-edge-found');
      })
      .catch(error => console.log(error));
  }, [setPopID]);

  useEffect(() => {
    if (!popID) return;

    fetch(
      `${ApiUrl.public}session`,
      {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json"
        }),
        body: JSON.stringify({
          "POPID": popID,
          "Cookies": hasCookies
        })
      }
    )
      .then(res => res.json())
      .then(response => {
        setUserAgent(response.userAgent);
        setTime(response.Timestamp);
        setClientIP(response.ClientIP);
        setHTTPVersion(response.HTTPVersion);
        setSessionID(response.SessionID);
        setSesstionStarted(true);
      })
      .catch(error => console.log(error));
    }, [popID, hasCookies, setUserAgent, setTime, setSesstionStarted, setHTTPVersion, setClientIP, setSessionID ]);

  useEffect(() => {
    if (!(ApiUrl.dnsWildcard && sessionID)) return;

    fetch(
      `https://${sessionID}.${ApiUrl.dnsWildcard}/egde-check.json`,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json"
        })
      }
    )
      .then(response => {
        setPopID(response.headers.get('x-amz-cf-pop') || 'no-edge-found');
      })
      .catch(error => console.log(error));
  }, [sessionID]);
  
  return (
    <Fragment>
      { sesstionStarted ? (
        <Box my={4}>
          <Typography variant="h4" align="center" component="h1">
            <strong>Session ID:</strong>
          </Typography>
          <Typography variant="h4" align="center" component="h1">
            {sessionID}
          </Typography>
          <Typography variant="subtitle1" align="center" component="p" gutterBottom>
            Please provide this session ID to your customer support representative.
          </Typography>
          <br />
          <Divider />
          <br />
          <Typography variant="h4" align="center" component="h1">
            <strong>Session Details:</strong>
          </Typography>
          <Typography align="center">
            <strong>POP ID:</strong> {popID}
          </Typography>
          <Typography align="center">
            <strong>Timestamp:</strong> {time}
          </Typography>
          <Typography align="center">
            <strong>User Agent:</strong> {userAgent}
          </Typography>
          <Typography align="center">
            <strong>Cookies:</strong> {hasCookies ? "Yes" : "No"}
          </Typography>
          <Typography align="center">
            <strong>HTTPVersion:</strong> {HTTPVersion}
          </Typography>
          <Typography align="center">
            <strong>ClientIP:</strong> {clientIP}
          </Typography>
        </Box>
       ) : (
        <Typography align="center">
          <strong>Loading...:</strong>
        </Typography>
       ) }
    </Fragment>
  );
}