import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TroubleShootTool from './client/TroubleShootTool'
import SessionDetail from './admin/SessionDetail'
import SessionList from './admin/SessionList'
import ApiUrl from '../services/ApiUrl'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
} from "react-router-dom";

export default function App() {
  const [ isAdmin, setIsAdmin ] = useState(false);

  useEffect(() => {
    fetch(
      `${ApiUrl.private}session/ping`
    )
      .then(response => response.json())
      .then(response => {
        if (response.status === "pong") {
          setIsAdmin(true);
        }
      });
  }, [setIsAdmin]);

  return (
    <Router>
      <Container maxWidth="md">
        <Switch>
          <Route exact path="/">
            <Box align="right">
              {
                isAdmin && (
                  <Button color="primary" component={RouterLink} to="/admin">
                    Admin Dashboard
                  </Button>
                )
              }
            </Box>
            <TroubleShootTool />
          </Route>
          <Route exact path="/admin" component={SessionList} />
          <Route exact path="/admin/:id" component={SessionDetail} />
        </Switch>
      </Container>
    </Router>
  );
}